import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Form, Input, Button, Modal, Popconfirm } from 'antd'
import { MailOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import './style.less'
import PageLayout from '../SignUp/SignUpLayout'
import { httpPost } from '../../services/http'
import { storeAuthToken } from '../../utils/auth-token'

interface Response {
    access_token: string
    token_type: string
    detail: string
}

const SignUp = () => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const error = (message: string) => {
        Modal.error({
            title: 'Signup failed',
            content: message,
        })
    }

    const manualFormFinish = () => {
        form.validateFields()
            .then((values) => {
                onFinish(values)
            })
            .catch((error) => {})
    }

    const onFinish = async (values: any) => {
        setLoading(true)
        try {
            const res = await httpPost<Response>('/auth/register', values)
            storeAuthToken(res.data?.access_token || '')
            if (typeof window !== 'undefined') {
                window.location.href = '/onboarding/'
            }
        } catch (err) {
            if (err.status === 400) {
                error(err.data?.detail)
            } else {
                error(err.message)
            }
            setLoading(false)
        }
    }

    return (
        <Form onFinish={onFinish} form={form}>
            <Form.Item
                hasFeedback
                name="email"
                rules={[
                    { required: true, message: 'Please enter your email!' },
                    { type: 'email', message: 'Not a valid email' },
                ]}
                className="signin-input"
            >
                <Input
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="name"
                rules={[{ required: true, message: 'Please enter your name!' }]}
                className="signin-input"
            >
                <Input
                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Full name"
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="password"
                rules={[
                    { required: true, message: 'Please enter your Password!' },
                    { min: 8, message: 'Password must contain at least 8 characters!' },
                ]}
                className="signin-input"
            >
                <Input
                    data-private
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="confirm"
                className="signin-input"
                rules={[
                    {
                        required: true,
                        message: 'Please enter your Password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                            }
                            return Promise.reject(
                                'The two passwords that you entered do not match!'
                            )
                        },
                    }),
                    { min: 8, message: 'Password must contain at least 8 characters!' },
                ]}
                dependencies={['password']}
            >
                <Input
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Repeat Password"
                />
            </Form.Item>
            <Form.Item>
                <Link target="_blank" className="link-header-terms" to="/terms/">
                    <h3>By signing up you agree to our terms!</h3>
                    <h3>(click to view)</h3>
                </Link>
            </Form.Item>
            <Form.Item>
                <Popconfirm
                    title="Do you agree to the terms?"
                    // visible={popConfirmVisible}
                    onConfirm={manualFormFinish}
                    // okButtonProps={{ htmlType: 'submit' }}
                    // onCancel={handleCancelDelete}
                >
                    <Button
                        className="landing-button"
                        shape="round"
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                    >
                        Create Account
                    </Button>
                </Popconfirm>
            </Form.Item>
            <Form.Item>
                <div style={{ flexDirection: 'row' }}>
                    <p>Already have an account?</p>
                    <Link to="/login/" replace>
                        <Button
                            className="landing-button"
                            type="primary"
                            shape="round"
                            size="small"
                        >
                            Login instead
                        </Button>
                    </Link>
                </div>
            </Form.Item>
        </Form>
    )
}

const SignUpContainer = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }
    }, [])
    return (
        <PageLayout title="Login">
            <div className="login-background">
                <div className="login-background-inner">
                    <div className="login-container">
                        <div>
                            <Link to="/" replace>
                                <div className="logoImgLarge" />
                            </Link>
                        </div>
                        <SignUp />
                    </div>
                    <br />
                </div>
            </div>
        </PageLayout>
    )
}

export default SignUpContainer
